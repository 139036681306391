import React from 'react'
import './home.css'
import ConvertKitSignupForm from '../kit/ConvertKitSignupForm'
import { RiSurveyFill, RiDatabase2Fill, RiAedFill } from "react-icons/ri";

export default function Home() {
    return (
        <div className="home">
            <div className="home-release-tag">Coming soon</div>
            <h1>Prioritize member feedback in <span>seconds</span></h1>
            <p>Tetherform is a survey and analytics platform that <span>automatically transforms responses into insights</span> so you can take action instead of sifting through data</p>
            <ConvertKitSignupForm/>

            <div style={{marginTop: "40px"}}>
                <div style={{textAlign: "center", marginBottom: "14px"}}>For professionals in...</div>
                <div className="home-whoisitfors">
                    <div className="home-whoisitfor">Startups</div>
                    <div className="home-whoisitfor">Associations</div>
                    <div className="home-whoisitfor">Conferences</div>
                    <div className="home-whoisitfor">Communities</div>
                    <div className="home-whoisitfor">Restaurants</div>
                    <div className="home-whoisitfor">Human Resources</div>
                    <div className="home-whoisitfor">Non-profits</div>
                    <div className="home-whoisitfor">Academics</div>
                    <div className="home-whoisitfor">Alumni Relations</div>
                    <div className="home-whoisitfor">Government</div>
                    <div className="home-whoisitfor">Churches</div>
                    <div className="home-whoisitfor">Education</div>
                </div>
            </div>
            <div className="home-argument-section">
                <div className="home-argument-image">
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/pexels-bertellifotografia-15141537.jpg" alt="Man giving a presentation" />
                </div>
                <div className="home-argument-text">
                    <h2><div className="home-argument-text-icon"><RiSurveyFill/></div>Step 1: Collect</h2>
                    <p>Create forms with our drag-and-drop editor to distribute however you see fit:</p>
                    <p>→ Web links<br/>→ QR codes<br/>→ Website direct</p>
                    <p>Responses are fully anonymous by design to protect your user's privacy.</p>
                </div>
            </div>
            <div className="home-argument-section">
                <div className="home-argument-text">
                    <h2><div className="home-argument-text-icon"><RiDatabase2Fill/></div>Step 2: Analyze</h2>
                    <p>Tetherform automatically organizes, analyzes, and delivers responses to your inbox so you don't have to waste time pouring over the data or exporting it into a separate dashboard.</p>
                    <p>Get automatic suggestions and trends from your members so you know exactly what to do next.</p>
                </div>
                <div className="home-argument-image">
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/pexels-divinetechygirl-1181619.jpg" alt="Women discussing important topics" />
                </div>
            </div>
            <div className="home-argument-section" style={{marginBottom: "80px"}}>
                <div className="home-argument-image">
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/pexels-divinetechygirl-1181615.jpg" alt="Women planning something meaningful" />
                </div>
                <div className="home-argument-text">
                    <h2><div className="home-argument-text-icon"><RiAedFill/></div>Step 3: Respond</h2>
                    <p>Manage suggested action items directly in Tetherform's To Do list for your team or send it to another tool, like Tetheros.</p>
                    <p>Make sure nothing slips through the cracks.</p>
                </div>
            </div>
            <ConvertKitSignupForm/>
        </div>
    )
}