import React from 'react'
import './header.css'

export default function Header() {
    return (
        <div className="header">
            Tether<span>form</span>
        </div>
    )
}
