import React, { useState } from 'react'
import './ConvertKitSignupForm.css'

export default function ConvertKitSignupForm() {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true)
        try {
            await fetch('https://app.convertkit.com/forms/7136399/subscriptions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email_address: email }),
            });
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };
    return (
        <div className="kit-form-container">
            <div className="kit-corm-container-cta">{!isSubmitted ? "Don't miss the launch" : "Thanks!" }</div>
            {!isSubmitted &&
            <form onSubmit={handleSubmit} className="seva-form formkit-form">
                <div data-style="clean">
                    <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                    <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
                        <div className="formkit-field">
                            <input value={email} onChange={(e) => setEmail(e.target.value)} className="formkit-input" name="email_address" aria-label="Email Address" placeholder="Enter your email address" required="" type="email"/>
                        </div>
                        <button data-element="submit" className="formkit-submit formkit-submit">
                            <span className="">Join the waitlist</span>
                        </button>
                    </div>
                </div>
            </form>
            }
            {isSubmitted &&
            <div className="kit-form-thanks">You'll get updates about TetherForm's launch in your inbox. Unsubscribe at any time.</div>
            }
      </div>
    )
}